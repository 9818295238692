<template>
  <section class="page-content">
    <h1>Partnere & Utviklere</h1>
    <row container :gutter="20">
      <column :xs="12" :md="12" :lg="12">
        <p class="subtitle">Økosystemet vårt er et samspill mellom flere aktører som bidrar til at systemet
          fungerer og utvides. Våre partnere er bidragsytere på flere nivåer og gir oss
          viktige tilbakemeldinger basert på reelle erfaringer med implementering av
          nettløsninger for sluttbrukere. Dette hjelper oss med å få en bedre forståelse av
          hele verdikjeden, fra programvareutvikling til implementerte webløsninger for
          sluttkunder.</p>
      <p class="subtitle">Kontakt <a href="mailto:kristian.stapnes@fernapps.no">kristian.stapnes@fernapps.no</a></p>
      </column>
    </row>
  </section>
</template>
<script>
export default {

}
</script>
